import './Architec.css';
import ArchitecData from './ArchitecData';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Architec = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        'https://archviewstudio.com/api/products'
      );
      setProducts(result.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="architec">
        <h1>Our Work</h1>

        {products.map((product) => (
          <Link
            key={product._id}
            to={`/product/${product._id}`}
            style={{ textDecoration: 'none', display: 'block' }}
          >
            <ArchitecData
              key={product.heading}
              cName={product.cName}
              heading={product.heading}
              text={product.text}
              img1={`/images/${product.images[0]}`}
              img2={`/images/${product.images[1]}`}
            />
          </Link>
        ))}
      </div>
    </>
  );
};

export default Architec;
