import React, { useEffect } from 'react';
import './Service.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ServiceData = (props) => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div className={props.cName}>
      <div className="service-text" data-aos="fade-left">
        <h2 className="service-heading">{props.heading}</h2>
        {props.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div className="service-image" data-aos="fade-right">
        <img src={`/images/${props.img1}`} alt="service" />
      </div>
    </div>
  );
};

export default ServiceData;
