import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import WorkProduct from './../Components/WorkProduct';

const MoreProduct = () => {
  return (
    <>
      <Navbar />
      <WorkProduct />
      <Footer />
    </>
  );
};

export default MoreProduct;
