import React from 'react';
import './App.css';
import {  Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Service from './routes/Service';
import Product from './routes/Product';
import MoreProduct from './routes/MoreProduct';
import AddProduct from './Components/AddProduct';
import AddWork from './Components/AddWork';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/service" element={<Service />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/work/:id" element={<MoreProduct />} />
      <Route path="/admin/product/add" element={<AddProduct />} />
      <Route path="/admin/work/add" element={<AddWork />} />
    </Routes>
  );
}

export default App;
