import { useEffect } from 'react';
import './Hero.css';
import 'aos/dist/aos.css';
const Hero = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const captions = document.querySelectorAll('.carousel-caption');
      const images = document.querySelectorAll('.carousel-img');

      captions.forEach((caption) => {
        if (caption) {
          caption.style.opacity = 1 - scrollY / 600; // You can adjust the division value for a smoother or faster fade
        }
      });

      images.forEach((image) => {
        if (image) {
          const imageOpacity = 1 - scrollY / 500;
          const imageScale = 1 + (scrollY / 500) * 0.8; // You can adjust the multiplication value for the zoom level
          
          // Apply the fade-out and zoom-in effect
          image.style.opacity = imageOpacity;
          image.style.transform = `scale(${imageScale})`;
        }
      });
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className={props.cName} data-aos="flip-right">
        <img src={props.heroImg} alt="HeroImg" />
        <div className="Hero-text">
          <h1>{props.title}</h1>
          <p>{props.text}</p>
          <a href={props.url} className={props.btnClass}>
            {props.btnName}
          </a>
        </div>
      </div> */}
      <div className={props.cName}>
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={props.img1}
                className="carousel-img d-block w-100"
                alt="sideimg"
              />
              <div className="carousel-caption ">
                <h5>Why partner with us?</h5>
              </div>
            </div>
            <div className="carousel-item ">
              <img
                src={props.img2}
                className="carousel-img d-block w-100"
                alt="sideimg"
              />
              <div className="carousel-caption ">
                <h5>Pursuit of perfection</h5>
                <p>
                  “If we chase perfection, we can reach excellence.” we are
                  committed to providing quality rendering services to our
                  clients that surpass the standards of our competitors.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={props.img3}
                className="carousel-img d-block w-100"
                alt="sideimg"
              />
              <div className="carousel-caption ">
                <h5>Time is money,</h5>
                <p>
                  One of our core values is speed!, We keep in mind your goals
                  and deadlines from the very beginning till we deliver the
                  results you celebrate.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={props.img4}
                className="carousel-img d-block w-100"
                alt="sideimg"
              />
              <div className="carousel-caption ">
                <h5>Great experiences need a great team</h5>
                <p>
                  As a team-oriented business, We are so bloody good at
                  attracting top-tier artists from around the world to
                  collaborate within your project.
                </p>
              </div>
            </div>
          </div>
          {/* <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Hero;
