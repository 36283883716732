import React, { Component } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { MenuItems } from './Menuitems';
import logo from '../assets/images/archviewlogo.png';

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <nav className="NavbarItems">
        <Link to={'/'}>
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <Link to={'/'} className={'nav-link'}>
          <h1 className="Navbar-logo"> archview studio </h1>
        </Link>
        <div className="menu-icon" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}
          ></i>
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((items, index) => {
            return (
              <li key={index}>
                <Link className={items.cName} to={items.url}>
                  <i className={items.icon}></i>
                  {items.title}
                </Link>
              </li>
            );
          })}
          {/* <button>Sign Up</button> */}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
