import './AboutUs.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import profileimg from '../assets/images/profile.jpg';


const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div className="about-container">
      <div className="text" data-aos="fade-right">
        <h1>About Us</h1>
      </div>
      <div className="phara" data-aos="fade-left">
        <p>
          Welcome to Archview Studio, where we bring architectural dreams to
          life! We're not your typical design and visualization firm – we go
          beyond imagination to craft unreal realities. More than just
          visualizing, we infuse architectural concepts with a mix of innovation
          and precision.
        </p>
        <p>
          {' '}
          Our global reach means we're ready to take on projects anywhere in the
          world. Whether you're an architect, developer, or a branding agency
          with a vision, we're your reliable partner.
        </p>
        <p>
          Your dreams, our design – the perfect combo. Let Archview Studio turn
          your vision into an extraordinary reality.
        </p>
      </div>

      <div className="text" data-aos="fade-right">
        <h1>Our Team</h1>
      </div>

      <div className="team-member" data-aos="fade-up">
        <div className="team-member-image">
          <img
            src={profileimg}
            alt="Akila Wijekoon"
            className="circle-image"
          />
        </div>
        <div className="team-member-info">
          <h3>Akila Wijekoon</h3>
          <p className="position">Founder / 3D Visualizer</p>
          <p className="description">
            A graduate Architect with a passion for 3D visualization. With over
            4 years of experience since 2019, Akila brings creativity and
            technical expertise to every project
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
