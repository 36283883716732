import AboutUs from '../Components/AboutUs';
import Hero from '../Components/Hero';
import Navbar from '../Components/Navbar';
import img1 from '../assets/images/sideimg-1a.jpg';
import img2 from '../assets/images/sideimg-2a.jpg';
import img3 from '../assets/images/sideimg-3a.jpg';
import img4 from '../assets/images/sideimg-4a.png';
import Footer from './../Components/Footer';

const About = () => {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        // heroImg="https://images.unsplash.com/photo-1600607687920-4e2a09cf159d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        // title="Architec"
        // text="Design your home"
        // btnName="Plan"
        // url="/"
        // btnClass="hide"
      />
      <AboutUs />
      <Footer />
    </>
  );
};

export default About;
