
import React from 'react';
import './Footer.css';
import logo from '../assets/images/archviewlogo.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="left">
        <img src={logo} alt="Archview Studio Logo" className="logo-img" />
      </div>

      <div className="middle">
        <h1>archview studio</h1>
        <h4>archview2020@gmail.com</h4>
        <div className="social">
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>

      <div className="right">
        <h4>© 2023. All Rights Reserved</h4>
        <h4>Colombo, Sri Lanka</h4>
      </div>
    </div>
  );
};

export default Footer;
