import ContactForm from '../Components/ContactForm';
import Hero from '../Components/Hero';
import Navbar from '../Components/Navbar';
// import contact from "../images/image-3.jpg";
import Footer from './../Components/Footer';
import img1 from '../assets/images/sideimg-1a.jpg';
import img2 from '../assets/images/sideimg-2a.jpg';
import img3 from '../assets/images/sideimg-3a.jpg';
import img4 from '../assets/images/sideimg-4a.png';

const Contact = () => {
  return (
    <>
      <Navbar />

      <Hero cName="hero" img1={img1} img2={img2} img3={img3} img4={img4} />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contact;
