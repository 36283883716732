
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ProductDetails.css'; // Import the CSS file for styling

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageSliderRef = useRef(null);
  const [isImagesOver, setIsImagesOver] = useState(false);

  const nextImage = () => {
    if (currentImageIndex < product.images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }

    if (currentImageIndex === product.images.length - 1) {
      setIsImagesOver(true);
    }
  };

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setIsImagesOver(false);
    }
  };

  useEffect(() => {
    if (imageSliderRef.current) {
      const offset = -currentImageIndex * imageSliderRef.current.clientWidth;
      imageSliderRef.current.style.transform = `translateX(${offset}px)`;
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://archviewstudio.com/api/products/${id}`
        );
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [id]);

  return (
    <div className="product-details">
      {product ? (
        <>
          <div className="product-card">
            <div className="product-item">
              <div className="product-text">
                <h2 className="product-heading">{product.heading}</h2>
                <p>{product.text}</p>
              </div>
              <img
                src={`/images/${product.images[0]}`}
                alt="Product"
                className="product-image"
                onError={(e) => {
                  // Handle image load errors here
                  console.error('Image load error', e);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
      {product && (
        <div className="product-images-row">
          <div className="image-slider">
            <div className="image-slider-container" ref={imageSliderRef}>
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={`/images/${image}`}
                  alt={`ProductImage${index + 1}`}
                  onError={(e) => {
                    // Handle image load errors here
                    console.error('Image load error', e);
                  }}
                  className={index === currentImageIndex ? 'active' : ''}
                />
              ))}
            </div>
          </div>
          <div className="button-container">
            <button
              onClick={prevImage}
              disabled={currentImageIndex === 0}
              style={{ left: 0 }}
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            <button
              onClick={nextImage}
              disabled={isImagesOver}
              style={{ right: 0 }}
            >
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
