import './Architec.css';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ArchitecData = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className={props.cName}>
      <div className="des-text" data-aos="fade-right">
        <h2>{props.heading}</h2>

        <p>{props.text}</p>
      </div>
      <div className="image" data-aos="fade-left">
        <img alt="img" src={props.img1} />
        <img alt="img" src={props.img2} />
      </div>
    </div>
  );
};

export default ArchitecData;
