import { useEffect, useState } from 'react';
import './Work.css';
import WorkData from './WorkData';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Work = () => {
  const [works, setWorks] = useState([]);
  const [displayedWorks, setDisplayedWorks] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        'https://archviewstudio.com/api/works'
      );
      setWorks(result.data);
      // Initially display the first three works
      setDisplayedWorks(result.data.slice(0, 3));
      // Show the "Load More" button if there are more than three works
      setShowMore(result.data.length > 3);
    };
    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const loadMoreWorks = () => {
    const nextWorks = works.slice(
      displayedWorks.length,
      displayedWorks.length + 3
    );
    setDisplayedWorks([...displayedWorks, ...nextWorks]);
    setShowMore(displayedWorks.length + nextWorks.length < works.length);
    setShowLess(true); // Show "Show Less" button after loading more works
  };

  const showLessWorks = () => {
    setDisplayedWorks(works.slice(0, 3));
    setShowMore(true); // Show "Load More" button after showing less works
    setShowLess(false); // Hide "Show Less" button
  };

  return (
    <div className="work">
      <h1>More Work</h1>

      <div className="workcard" data-aos="fade-up">
        {displayedWorks.map((work) => (
          <div key={work._id} className="w-card-wrapper">
            <Link
              to={`/work/${work._id}`}
              style={{ textDecoration: 'none', display: 'contents' }}
            >
              <WorkData
                key={work._id}
                image={`/images/${work.images[0]}`}
                heading={work.heading}
              />
            </Link>
          </div>
        ))}
      </div>

      {showMore && (
        <div className="load-more-button">
          <button onClick={loadMoreWorks}>Load More</button>
        </div>
      )}
      {showLess && (
        <div className="load-more-button">
          <button onClick={showLessWorks}>Show Less</button>
        </div>
      )}
    </div>
  );
};

export default Work;
