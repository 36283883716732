import axios from 'axios';
import React, { useState } from 'react';

const AddProduct = () => {
  const [cName, setcName] = useState('');
  const [heading, setHeading] = useState('');
  const [text, setText] = useState();
  const [images, setImages] = useState([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);

  function sendData(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append('cName', cName);
    formData.append('heading', heading);
    formData.append('text', text);

    // Append multiple image files to the formData
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    console.log('FormData:', formData);

    axios
      .post('https://archviewstudio.com/api/products/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        alert('Product Added');
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const files = e.target.files;

    // Create an array to hold the image files
    const selectedImages = [];

    // Iterate through the selected files and handle each one
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onloadend = () => {
        selectedImages.push(file); // Add the file to the selectedImages array
        setImagePreviewUrls([...imagePreviewUrls, reader.result]);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }

    // Update the state with the selected images array
    setImages(selectedImages);
  };

  return (
    <div className="container">
      <form onSubmit={sendData}>
        <div>
          <label htmlFor="cName">className:</label>
          <input
            type="text"
            id="cName"
            value={cName}
            onChange={(event) => setcName(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="heading">Heading:</label>
          <input
            id="heading"
            value={heading}
            onChange={(event) => setHeading(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="text">Text:</label>
          <input
            id="text"
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="images">Images:</label>
          <input
            type="file"
            id="images"
            className="form-control-file"
            accept="image/*"
            multiple // Enable multi-file selection
            onChange={handleImageChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
