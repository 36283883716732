import Navbar from "../Components/Navbar";
import ServiceDetails from "../Components/ServiceDetails";
import Footer from "./../Components/Footer";
const Service = () => {
    return ( 
        <>
        <Navbar />
      <ServiceDetails/>
      <Footer/>
      </>
     );
}
 
export default Service;