const data = {
  products: [
    {
      cName: 'service-des',
      heading: 'Exterior rendering',
      text:
        "Unlock Architectural Excellence with Archview Studio's Exterior Rendering Services. \nArchview Studio takes pride in delivering unparalleled exterior rendering services, where architectural brilliance meets digital precision. Our team of seasoned 3D artists and designers is dedicated to translating your conceptual designs into visually stunning and realistic representations.\nImmerse your stakeholders in a virtual journey through our meticulously crafted exterior renderings. Precision in detailing, mastery of lighting, and an acute understanding of materials converge to create a visual narrative that not only captivates but also communicates the essence of your architectural vision. Whether residential, commercial, or industrial exteriors, Archview Studio elevates your designs to new heights.\n Experience the pinnacle of visualization as our photorealistic renderings showcase the interplay of form and function, providing an invaluable tool for presentations, marketing, and project development. Trust Archview Studio to transform your architectural concepts into captivating visual experiences, setting the stage for success.",
      img1: 'image-rendering.jpg',
    },
    {
      cName: 'service-reverse',
      heading: 'Interior rendering',
      text:
        " Elevate Interior Design with Archview Studio's Professional Rendering Services\n Archview Studio redefines interior rendering with a commitment to precision, creativity, and a keen eye for detail. Our team of expert 3D artists and interior designers collaborates seamlessly to breathe life into your interior spaces, creating immersive visualizations that go beyond aesthetics to convey the true essence of your designs.\n Step into a realm of sophistication and realism as Archview Studio's interior renderings showcase the meticulous curation of every element in your space. From residential sanctuaries to commercial hubs and hospitality environments, our renderings encapsulate the unique character of each design, telling a compelling story of form and function.\nOur renderings transcend traditional visualizations, offering a refined glimpse into the future of interior spaces. Experience the seamless integration of light, texture, and spatial arrangement with Archview Studio's bespoke interior rendering services. Elevate your design presentations and project marketing with our commitment to excellence and professionalism.",
      img1: 'image-interior.jpg',
    },
  ],
};
// Function to split text into paragraphs based on empty lines
const newData = {
  products: data.products.map((product) => ({
    ...product,
    text: product.text.split('\n').map((paragraph) => paragraph.trim()),
  })),
};
export default newData;
