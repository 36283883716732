import ServiceData from './ServiceData';
import './Service.css';
import newData from './../data';

const ServiceDetails = () => {
  return (
    <div className="service-details">
      {newData.products.map((products) => (
        <div className="service-card">
          <ServiceData
            cName={products.cName}
            heading={products.heading}
            text={products.text}
            img1={products.img1}
          />
        </div>
      ))}
    </div>
  );
};

export default ServiceDetails;
