import { useEffect, useState } from 'react';
import './ContactForm.css';
import Modal from 'react-modal';
import axios from 'axios'; // Import Axios
import Aos from 'aos';
import 'aos/dist/aos.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    
    
  },
};

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    try {
      // Create an object to hold the form data
      const formData = {
        firstName,
        lastName,
        email,
        mobile,
        message,
      };

      // Send a POST request to your MongoDB server using Axios
      const response = await axios.post(
        'https://archviewstudio.com/api/contact',
        formData
      );

      // Handle the response here if needed
      console.log('Data sent successfully:', response.data);

      // Clear the form after submission
      setFirstName('');
      setLastName('');
      setEmail('');
      setMobile('');
      setMessage('');

      // Close the modal
      closeModal();
    } catch (error) {
      // Handle any errors that occur during the POST request
      console.error('Error sending data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Open the modal
    openModal();
  };
  return (
    <div className="container">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} data-aos="fade-left">
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="First Name"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          id="mobile"
          name="mobile"
          placeholder="Mobile"
          required
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <h4>Message </h4>
        <textarea
          required
          value={message}
          name="message"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <input type="submit" value="Send" id="button" />
      </form>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Input Modal"
        style={customStyles}
      >
        <h2>Confirmation</h2>
        <p>First Name: {firstName}</p>
        <p>Last Name: {lastName}</p>
        <p>Email: {email}</p>
        <p>Mobile: {mobile}</p>
        <p>Message: {message}</p>
        <div className="load-more-button">
        <button onClick={handleConfirm}>Confirm</button>
        <button onClick={closeModal}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactForm;
