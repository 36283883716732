import React from 'react';
import ProductDetail from '../Components/ProductDetail';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Product = () => {
  return (
    <div>
      <Navbar />
      <ProductDetail />
      <Footer />
    </div>
  );
};

export default Product;
